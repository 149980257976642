<template>
	<div>
		<client-only>
			<component :is="'script'">
				(function (d, s, id, h) { var ftjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; var
				js = d.createElement(s); js.id = id; js.src = "https://cdn.formitable.com/sdk/v1/ft.sdk.min.js"; h &&
				(js.onload = h); ftjs.parentNode.insertBefore(js, ftjs); }(document, 'script', 'formitable-sdk',
				function () { FT.load('Analytics'); }));
			</component>
			<div
				class="ft-widget-b2"
				data-restaurant="ea2bd095"
				data-open="1500"
				data-open-mobile="false"
				data-color="#f7da4a"
				data-language="auto"
				data-tag="Website"
				data-toolbar="true"
				data-toolbar-mobile="true"
			/>
		</client-only>
		<nav class="main-nav" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link class="logo" :to="{ path: localePath('/', locale), hash: '#top' }" />
						<div class="buttons-wrapper">
							<social-media :socials="socials" />
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon class="icon icon-phone" icon="fal fa-phone" size="1x" />
							</a>
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon class="icon icon-mail" icon="fal fa-envelope" size="1x" />
							</a>
							<div class="lang-nav desktop">
								<font-awesome-icon class="icon icon-lang" icon="fal fa-globe" size="1x" />
								<select v-if="langmenu.length" @change="switchLanguage($event)">
									<option
										v-for="item in langmenu"
										:key="item.language"
										:data-url="item.filename"
										:value="item.language"
										:selected="item.language == locale"
									>
										{{ item.language }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const switchLanguage = (event) => {
	const option = event.target.options[event.target.selectedIndex];

	return navigateTo(option.dataset.url + window.location.hash);
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	z-index: 1000;
	top: 0;
	padding: 15px 0;
	position: absolute;
	width: 100%;

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.lang-nav {
	border-left: 1px solid #fff;
	height: 40px;
	padding: 0 0 0 15px;
	margin: 0 0 0 5px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	.icon {
		color: #fff;
		margin: 0 5px 0 0;
	}

	select {
		width: auto;
		padding: 0;
		margin: 0 20px 0 0;
		cursor: pointer;
		appearance: none;
		background: url('~/assets/images/icon-dropdown-white.png') right 2px top 8px no-repeat;
		background-size: 6px;
		min-width: 40px;
		border: none;
		text-transform: uppercase;
		font-size: 16px;
		background-color: none;
		color: #fff;
		font-family: $body-font-family;
		font-weight: 700;
		letter-spacing: 2px;
	}

	option {
		color: $body-color;
		background: #fff;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: block;
			justify-content: center;
			align-items: center;

			i,
			svg {
				margin-right: 7px;
			}
		}

		.icon {
			display: none;
			font-size: 13px;
			margin-right: 5px;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.logo {
	width: 124px;
	height: 118px;
	background: url('~/assets/images/logo-door74-full-brown.png') no-repeat center center;
	background-size: 124px;
}

.nav-content {
	margin-right: 30px;
	flex-grow: 1;
	text-align: right;

	.nav-item a {
		color: $body-color;
		font-size: $button-font-size;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 500;
		margin: 0 15px;
		padding: 6px 0;
		letter-spacing: 1px;
		transition: none;
		border-bottom: 2px solid transparent;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
			border-color: $cta-color;
		}
	}

	.menu-close {
		color: #000;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;
		}

		a {
			font-size: 15px;
			color: $body-color;

			&.router-link-exact-active,
			&:hover {
				color: $cta-color;
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

.buttons-wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	> a {
		margin: 0 15px 0 0;
	}

	.socials,
	a {
		color: #fff;

		&:hover {
			color: $cta-color;
		}
	}
}

@media (max-width: 1200px) {
	.nav-bar .buttons .icon {
		display: unset;
	}

	.main-nav {
		box-shadow: 0 0 10px rgb(0 0 0 / 5%);

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 20px;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: $primary-background-color;
			text-align: center;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}
}

@media (max-width: 880px) {
	.nav-bar {
		.buttons .button {
			padding: 16px;
		}
	}

	.main-nav {
		.row {
			padding: 0;
			width: 100%;

			.column12 {
				padding: 0 10px;
			}
		}
	}
}

@media (max-width: 580px) {
	.lang-nav select {
		margin: 0 3px 0 0;
	}

	.logo {
		width: 100px;
		height: 95px;
		background: url('~/assets/images/logo-door74-small-brown.png') no-repeat center center;
		background-size: 100px;
	}

	.nav-bar .buttons .button {
		.icon,
		i,
		svg {
			margin-right: 0;
			font-size: 16px;
		}
	}

	.nav-bar .buttons span {
		display: none;
	}
}
</style>
