<template>
	<div class="container" :class="{ 'no-heading-image': !page.headingImage && !page.vimeoID }">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />

			<client-only>
				<component
					:is="'script'"
					defer
					data-assistant="66f6a7352d6cf74f0a5e1f78"
					src="https://hiring-assistant.soigne.app/sdk.js"
				/>
			</client-only>
		</Head>

		<navigation />
		<slot />

		<footers />

		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image-webp="defaults[locale].popUp.imageWebp"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content"
		/>
		<cookie-consent
			v-if="page && defaults[locale].cookieConsent.content"
			:content="defaults[locale].cookieConsent.content"
			:position="defaults[locale].cookieConsent.position"
		/>
		<to-top />
	</div>
</template>

<script setup>
import AOS from 'aos';
import 'aos/dist/aos.css';

const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.pageYOffset >= 50) {
		fixedElements.forEach((el) => el.classList.add('scroll'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('scroll'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
	AOS.init();
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl,
	twitterTitle: metaTitle,
	twitterDescription: metaDescription || '',
	twitterCreator: website.restaurantName,
	twitterImage: website.structuredImage,
	ogTitle: metaTitle,
	ogUrl: currentUrl,
	ogImage: website.structuredImage,
	ogDescription: metaDescription || '',
	ogSiteName: website.restaurantName,
});

useHead({
	script: [
		{
			'@context': 'http://schema.org',
			'@type': 'Restaurant',
			'@id': `${config.public.siteUrl}#restaurant`,
			image: website.structuredImage,
			url: config.public.siteUrl,
			name: website.restaurantName,
			logo: `${config.public.siteUrl}/images/logo-door74-full-brown.png`,
			telephone: website.phone,
			email: website.email,
			address: {
				'@type': 'PostalAddress',
				streetAddress: `${website.street} ${website.number}, ${website.city}`,
				postalCode: website.postalCode,
			},
		},
	],
});
</script>
